<template>
    <div class="desk-rank-list-component">
        <el-dialog
            :title="rank_config.title"
            v-model="rank_config.is_show"
            width="600px"
            :show-close="false"
            :close-on-click-modal="false"
            :close-on-press-escape="false">
            <div class="rank-dialog">
                <table-component :table="rank_config.table">
                    <template #default="data">
                        <div>{{data.scope.row[data.item.prop]}}</div>
                    </template>
                </table-component>
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="closeReleaseRank">关闭</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>
<script>
import {cashierApi} from '@/api'
import tableComponent from '@/components/table'
export default {
    name:'desk-rank-list',
    components:{
        tableComponent
    },
    props:{
        is_show:[Boolean]
    },
    data(){
        return{
            rank_config:{
                title:'实时排行',
                is_show:false,
                table:{
                    is_index:true,
                    column_key:[
                        {
                            prop:'room_name',
                            label:'台位名'
                        },{
                            prop:'total_price',
                            label:'消费总额'
                        },
                    ],
                    list:[]
                }
            },
        }
    },
    watch:{
        is_show:{ 
            handler(newVal){
                this.rank_config.is_show = newVal
                if(newVal){
                    this.roomRealTimeRanking()
                }
            },
            immediate:true,
            deep:true
        },
        'rank_config.is_show':{
            handler(newVal){
                this.$emit('update:is_show',newVal)
            },
            deep:true
        }
    },
    methods:{
      /**
         * 获取实时排行数据
         */
        roomRealTimeRanking(){
            cashierApi.roomRealTimeRanking().then(res=>{
                this.rank_config.table.list = res.data
            })
        },
        /**
         * 关闭实时排行弹窗
         */
        closeReleaseRank(){
            this.rank_config.is_show = false
            this.rank_config.table.list = []
        },
    }
}
</script>
<style lang="scss" scoped>
.desk-rank-list-component{

}
</style>