<template>
    <div class="desktop-info-component" :style="{top:top_height+'px'}">
        <div class="nav-list">
            <div class="nav-item">当前信息</div>
        </div>
        <div class="info-box" v-if="current_desk_info">

            <!-- 空台 start -->
            <div class="info c2" v-if="current_desk_info.ro_status == 1">
                <div class="info-top">
                    <h2 class="desktop-number">{{ current_desk_info.ro_room_name }}</h2>
                    <div>
                        <span class="time" v-if="current_desk_info.ro_is_lock == 1"><i
                                class="desltop-icon desltop-icon1">空</i></span>
                    </div>
                </div>
                <div class="info-list">
                    <div class="item">
                        <span class="left big-text">类型：{{ current_desk_info.ro_pf_type_name }}</span>
                    </div>
                    <div class="item">
                        <span class="left">可容纳：{{ current_desk_info.ro_capacity_people_number }}</span>
                    </div>
                    <div class="item">
                        <span class="left">低消：{{ current_desk_info.ro_low_consume }}<i class="done-text"></i></span>
                    </div>
                    <div class="item" v-if="current_desk_info.ro_is_lock == 1">空台可开</div>
                    <div class="item" v-else-if="current_desk_info.ro_is_lock == 2">锁台中</div>
                </div>
            </div>
            <!-- 空台 end -->

            <!-- 开台 start -->
            <div class="info c1" v-else-if="current_desk_info.ro_status == 2">
                <div class="info-top">
                    <h2 class="desktop-number">{{ current_desk_info.ro_room_name }}</h2>
                    <span class="time">
                        {{ current_desk_info.open_room_time }}
                        <i v-if="current_desk_info.later_pay == 2" class="desltop-icon desltop-icon3">后</i>
                        <i class="desltop-icon desltop-icon3">开</i>
                    </span>
                </div>
                <div class="info-list">
                    <div v-if="current_desk_info.VIP >= 1" class="vip-box">
                        <span class="vip-label">VIP消费</span>
                    </div>
                    <div class="item">
                        <div class="left">
                            <span class="big-text">{{ current_desk_info.user_name }}&nbsp;{{ current_desk_info.phone }}</span>
                            <span class="unline" @click="openChangeMemberPopup(2)">更换会员</span>
                        </div>
                        <span class="right">主题：{{ current_desk_info.theme_name }}</span>
                    </div>
                    <div class="item">
                        <div class="left">
                            <span>订台人：{{ current_desk_info.staff_name }}</span>
                            <span class="unline" @click="openChangeStaffPopup(2)">更换订台人</span>
                        </div>
                        <span class="right">服务：{{ current_desk_info.waiter_name }}</span>
                    </div>
                    <div class="item">
                        <span class="left">低消：{{ current_desk_info.ro_low_consume }}<i class="done-text"></i></span>
                    </div>
                    <div class="item">
                        <span class="left">备注：{{ current_desk_info.remarks }}<i class="done-text"></i></span>
                    </div>
                </div>
            </div>
            <!-- 开台 end -->

            <!-- 预定 start -->
            <div class="info c3" v-else-if="current_desk_info.ro_status == 3 && current_desk_info.reserve">
                <div class="info-top">
                    <h2 class="desktop-number">{{ current_desk_info.ro_room_name }}</h2>
                    <span class="time">
                        <i v-if="current_desk_info.reserve && current_desk_info.reserve.reserve_status == 5"
                           class="desltop-icon desltop-icon6">超时</i>
                        <i class="desltop-icon desltop-icon2">预</i>
                    </span>
                </div>
                <div class="info-list">
                    <div class="item">
                        <div class="left">
                            <span class="big-text">{{ current_desk_info.reserve.name }}</span>
                            <span class="unline" @click="openChangeMemberPopup(2)">更换会员</span>
                        </div>
                        <span class="right">主题：{{ current_desk_info.reserve.theme_name }}</span>
                    </div>
                    <div class="item">
                        <div class="left">
                            <span>订台人：{{ current_desk_info.reserve.sales_name }}</span>
                            <span class="unline" @click="openChangeStaffPopup(2)">更换订台人</span>
                        </div>
                    </div>
                    <div class="item">
                        <span class="left">定金：{{ current_desk_info.ro_low_consume }}</span>
                    </div>
                    <div class="item">
                        <span class="left">预定时间：{{ current_desk_info.reserve.arrival_time }}</span>
                    </div>
                    <div class="item">
                        <span class="left">备注：{{ current_desk_info.reserve.remarks }}<i class="done-text"></i></span>
                    </div>
                </div>
            </div>
            <!-- 预定 end -->

            <!-- 不等于空台 -->
            <div class="step-box" v-if="current_desk_info.ro_status != 1">
                <el-steps direction="vertical" :active="desk_record_list.length">
                    <el-step icon="el-icon-circle-check" v-for="(item,index) of desk_record_list" :key="index">
                        <template #description>
                            <div class="step-item" :style="bgColor(item.record_type)">
                                <div class="top">
                                    <span>{{ item.updated_at }}</span>
                                </div>
                                <!-- 订单 start -->
                                <div>操作：{{ item.operator }}</div>
                                <div v-if="item.record_type == 5">
                                    <ul>
                                        <div>订单号：{{ item.record_info.order_number }}</div>
                                        <div v-if="item.record_info.status_name">
                                            <span>订单状态：</span>
                                            <el-tag type="info" size="mini">{{ item.record_info.status_name }}</el-tag>
                                        </div>
                                        <div v-if="item.record_info.refund_status_name">
                                            <span>退款状态：</span>
                                            <el-tag type="info" size="mini">{{ item.record_info.refund_status_name }}
                                            </el-tag>
                                        </div>
                                        <div v-if="item.record_info.pay_way_name">
                                            支付方式：{{ item.record_info.pay_way_name }}
                                        </div>
                                        <li class="goods-item" v-for="(info_item,info_index) of item.record_info.info"
                                            :key="info_index">
                                            <div>商品：{{ info_item.goods }}&nbsp;&nbsp;&nbsp;x{{ info_item.num }}</div>
                                            <div>价格：{{ info_item.price }}</div>
                                        </li>
                                    </ul>
                                </div>
                                <!-- 订单 end -->
                                <!-- 赠送 start -->
                                <div v-else-if="item.record_type == 10">
                                    <ul>
                                        <div>赠送单</div>
                                        <div>订单号：{{ item.record_info.order_number }}</div>
                                        <li class="goods-item" v-for="(info_item,info_index) of item.record_info.info"
                                            :key="info_index">
                                            <div>
                                                <span>商品：{{ info_item.goods_name }}&nbsp;&nbsp;&nbsp;x{{ info_item.goods_num }}</span>
                                                <el-tag class="global-ml-10" type="info"
                                                        v-if="info_item.goods_type === 1" size="mini">普通商品
                                                </el-tag>
                                                <el-tag class="global-ml-10" type="info"
                                                        v-if="info_item.goods_type === 2" size="mini">套餐商品
                                                </el-tag>
                                                <el-tag class="global-ml-10" type="info"
                                                        v-if="info_item.goods_type === 3" size="mini">点舞商品
                                                </el-tag>
                                            </div>
                                            <div>价格：{{ info_item.selling_price }}</div>
                                        </li>
                                    </ul>
                                </div>
                                <!-- 赠送 end -->
                                <!-- 存取酒 start -->
                                <div v-else-if="item.record_type == 6">
                                    <div>
                                        <span v-if="item.record_info.status == 1">存酒单</span>
                                        <span v-else-if="item.record_info.status == 2">取酒单</span>
                                    </div>
                                    <ul>
                                        <li class="goods-item" v-for="(info_item,info_index) of item.record_info.info"
                                            :key="info_index">
                                            <div>存取单号：{{ info_item.alcohol_odd_num }}</div>
                                            <div>商品名称：{{ info_item.goods }}</div>
                                            <div>数量：x{{ info_item.num }}</div>
                                            <div>余量：{{ info_item.allowance }}%</div>
                                        </li>
                                    </ul>
                                </div>
                                <!-- 存取酒 end -->
                                <!-- 订单更换台位 start -->
                                <div v-else-if="item.record_type == 16">
                                    <div>
                                        <div>订单编号：{{ item.record_info.order_number }}</div>
                                        <div>{{ item.record_info.record_info }}</div>
                                    </div>
                                </div>
                                <!-- 订单更换台位 end -->
                                <div v-else class="content">{{ item.record_info }}</div>
                            </div>
                        </template>
                    </el-step>
                </el-steps>
            </div>

            <div class="info-control" v-if="check_type == 1">
                <div class="btn-box">
                    <el-button @click="openRunningWaterPopup(1)" v-if="current_desk_info.ro_status == 1"
                               v-allow="['reserveDesk']">预定
                    </el-button>
                </div>
            </div>

            <div class="info-control" v-else>
                <div v-if="current_desk_info.ro_is_lock == 2">
                    <el-button class="control-btn" @click="deblockFun" v-allow="['unlockDesk']">解锁</el-button>
                </div>
                <div v-else-if="current_desk_info.ro_is_lock == 1">
                    <el-button class="control-btn" @click="lockFun" v-if="current_desk_info.ro_status != 9"
                               v-allow="['lockDesk']">锁台
                    </el-button>
                    <el-button class="control-btn" @click="recoveryFun" v-if="current_desk_info.ro_status == 1">复台
                    </el-button>
                    <div class="btn-box">
                        <el-button class="control-btn" @click="openRunningWaterPopup(0)"
                                   v-if="current_desk_info.ro_status == 1 || current_desk_info.ro_status == 3"
                                   v-allow="['openDesk']">开台
                        </el-button>
                        <el-button class="control-btn" v-if="current_desk_info.ro_status == 3" @click="clearReserveFun"
                                   v-allow="['clearDesk']">置空台
                        </el-button>
                        <el-button class="control-btn" @click='getDeskList(0)'
                                   v-if="current_desk_info.ro_status == 2 || current_desk_info.ro_status == 4 || current_desk_info.ro_status == 3"
                                   v-allow="['forwardDesk']">转台
                        </el-button>
                        <el-button class="control-btn" @click="openRunningWaterPopup(1)"
                                   v-if="current_desk_info.ro_status == 1" v-allow="['reserveDesk']">订台
                        </el-button>
                        <el-button class="control-btn" @click='getDeskList(2)'
                                   v-else-if="current_desk_info.ro_status == 2 || current_desk_info.ro_status == 4"
                                   v-allow="['mergeDesk']">并台
                        </el-button>
                        <el-button class="control-btn" @click='getDeskList(1)'
                                   v-if="current_desk_info.ro_status == 2 || current_desk_info.ro_status == 4 || current_desk_info.ro_status == 3"
                                   v-allow="['exchangeDesk']">换台
                        </el-button>
                        <el-button class="control-btn" @click="directKt" v-if="current_desk_info.ro_status == 1"
                                   v-allow="['directOpenDeskBtn']">直接开台
                        </el-button>
                        <el-button class="control-btn" @click="directDt" v-if="current_desk_info.ro_status == 1"
                                   v-allow="['directReverseBtn']">直接订台
                        </el-button>
                    </div>
                    <el-button class="control-btn clear-btn" @click="cleanFun" v-if="current_desk_info.ro_status == 2"
                               v-allow="['clearDesk']">清台
                    </el-button>
                </div>
            </div>
        </div>

        <!-- 开台信息 start -->
        <el-dialog
                :title="popup_config.title"
                v-model="popup_config.is_show"
                width="600px"
                :show-close="false"
                :close-on-click-modal="false"
                :close-on-press-escape="false">
            <el-form :model="popup_config.ruleForm" :rules="popup_config.rules" ref="ruleForm" label-width="100px"
                     class="start-desk-box">
                <el-form-item label="会员名称" prop="user_name">
                    <div class="global-flex">
                        <el-input class="input" v-model="popup_config.ruleForm.user_name" placeholder="请输入会员名称"
                                  :disabled="popup_config.disabledMember"></el-input>
                        <el-button class="check-member-btn" type="text" @click="openChangeMemberPopup(1)">选择会员
                        </el-button>
                        <el-button class="check-member-btn" type="text" @click="checkSanke">选择散客</el-button>
                    </div>
                </el-form-item>
                <el-form-item label="手机号码" prop="phone">
                    <el-input class="input" v-model="popup_config.ruleForm.phone" placeholder="请输入手机号"
                              :disabled="popup_config.disabledMember"></el-input>
                </el-form-item>
                <el-form-item label="称呼" prop="sex">
                    <el-radio-group v-model="popup_config.ruleForm.sex">
                        <el-radio :label="1">先生</el-radio>
                        <el-radio :label="2">女士</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="开台时间" prop="open_room_time" v-if="popup_config.type == 0">
                    <el-col :span="12">
                        <el-form-item>
                            <el-date-picker type="datetime" format="YYYY-MM-DD HH:mm" placeholder="选择日期时间"
                                            v-model="popup_config.ruleForm.open_room_time"
                                            style="width: 100%;"></el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item label="预定到店时间" prop="open_room_time" v-if="popup_config.type == 1">
                    <el-col :span="12">
                        <el-form-item>
                            <el-date-picker type="datetime" format="YYYY-MM-DD HH:mm" placeholder="选择日期时间"
                                            :disabled='popup_config.disabled'
                                            v-model="popup_config.ruleForm.open_room_time"
                                            style="width: 100%;"></el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item label="订台人" prop="staff_name">
                    <el-autocomplete :disabled="popup_config.disabledStaff" value-key="name" class="input inline-input"
                                     v-model="popup_config.ruleForm.staff_name" :fetch-suggestions="querySearch"
                                     placeholder="支持简称搜索" :trigger-on-focus="false"
                                     @select="handleSelect"></el-autocomplete>
                    <el-button class="check-member-btn clear" type="text" v-if="popup_config.disabledStaff"
                               @click="clearStaff">清空
                    </el-button>
                    <el-button class="check-member-btn" type="text" @click="openChangeStaffPopup(1)">选择订台人
                    </el-button>
                </el-form-item>
                <el-form-item label="人数" prop="people_number" v-if="popup_config.type == 0">
                    <el-input class="input" v-model="popup_config.ruleForm.people_number"
                              placeholder="请输入人数"></el-input>
                </el-form-item>
                <el-form-item label="预定人数" prop="people_number" v-if="popup_config.type == 1">
                    <el-input v-model="popup_config.ruleForm.people_number" placeholder="请输入人数"></el-input>
                </el-form-item>
                <el-form-item label="主题" prop="theme_id">
                    <el-select v-model="popup_config.ruleForm.theme_id" placeholder="请选择">
                        <el-option v-for="(item,index) in popup_config.theme_list" :key="index" :label="item.label"
                                   :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="备注" prop="remarks">
                    <el-input class="input" type="textarea" :rows="2" placeholder="请输入内容"
                              v-model="popup_config.ruleForm.remarks"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button @click="resetForm">取消</el-button>
                    <el-button type="primary" @click="saveHandle">
                        {{ popup_config.type == 0 ? '立即开台' : '立即预定' }}
                    </el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <!-- 开台信息 end -->


        <!-- 转台信息 start -->
        <el-dialog
                :title="desk_config.title"
                v-model="desk_config.is_show"
                width="350px"
                :close-on-click-modal="false"
                :close-on-press-escape="false">
            <el-form>
                <h2 class="bottom" v-if="current_desk_info.total_price < current_desk_info.ro_low_consume">
                    【{{ current_desk_info.ro_room_name }}】当前消费￥{{ current_desk_info.total_price }}元不足【{{ current_desk_info.ro_room_name }}】低消标准！</h2>
                <h2 class="bottom" v-else>已满足低消标准！</h2>
                <el-form-item>
                    <el-button @click="forwardHandle(2)"
                               v-if="current_desk_info.total_price < current_desk_info.ro_low_consume">豁免低消
                    </el-button>
                    <el-button type="primary" @click="forwardHandle(1)">直接转台</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>


        <!-- 台位列表 start -->
        <!-- @agree="memberAgreeFun" -->
        <tableDeskDialogComponent v-model:is_show="con_config.is_show" :current_desk_info="current_desk_info"
                                  :list="select_list" :type="con_config.type"
                                  @agree="conConfigAgreeFun"></tableDeskDialogComponent>
        <!-- 台位列表 end -->

        <!-- 选择会员 start -->
        <memberDialogComponent v-model:is_show="member_dialog_config.is_show"
                               @agree="memberAgreeFun"></memberDialogComponent>
        <!-- 选择会员 end -->

        <!-- 选择营销 start -->
        <staffDialogComponent v-model:is_show="staff_dialog_config.is_show"
                              @agree="staffAgreeFun"></staffDialogComponent>
        <!-- 选择营销 end -->

        <!-- 开台记录 start -->
        <recoveryDialogComponent v-model:is_show="recovery_config.is_show" :room_id="recovery_config.room_id"
                                 @agree="recoveryAgreeFun"></recoveryDialogComponent>
        <!-- 开台记录 end -->


    </div>
</template>
<script>
import {cashierApi, memberApi} from '@/api'
import {customFormatFullTime, formatFullDate, formatTime} from '@/assets/js/utils'
import tableComponent from '@/components/table'
import {nextTick} from 'vue'
import {orderMixins} from '@/mixins'
import staffDialogComponent from '@/components/staffDialog'
import memberDialogComponent from '@/components/memberDialog'
import recoveryDialogComponent from '@/components/recoveryDialog'
import tableDeskDialogComponent from '@/components/tableDeskDialog'

export default {
    name: 'desktop-info-component',
    components: {
        tableComponent,
        staffDialogComponent,
        memberDialogComponent,
        recoveryDialogComponent,
        tableDeskDialogComponent
    },
    mixins: [orderMixins],
    props: {
        /**
         * 点击的台位信息（台位列表带过来的）
         */
        desk_item: {
            type: Object,
            default: null
        },
        /**
         * 0、未设置预定时间 1、预定搜索
         */
        check_type: {
            type: String,
            default: null
        },
        /**
         * 台位列表
         */
        desktop_list: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            recovery_config: {
                is_show: false,
                room_id: '',//台位id
            },
            member_dialog_config: {
                is_show: false,
                type: 0, //1选择会员 2更换会员
            },
            staff_dialog_config: {
                is_show: false,
                type: 0, //1选择订台人 2更换订台人
            },
            top_height: 0,
            forward_config: {
                room_id: '', //选择转过去的台位id
                id: ''
            },
            popup_config: {
                title: '开台',
                is_show: false,
                type: 0, //0 开台 1 预定
                disabledStaff: false,//是否禁用订台人输入
                disabledMember: false,//是否禁用客户姓名/手机号输入
                ruleForm: {
                    id: '',
                    u_id: '',
                    user_name: '',
                    sex: 1,
                    phone: '',
                    room_name: '',
                    open_room_time: '',
                    staff_name: '',
                    staff_id: '',
                    room_id: '',
                    people_number: '',
                    open_room_type: '',
                    theme_id: '',
                    theme_name: '',
                    status: 1,
                    remarks: '',
                    grade_name: '',
                    grade_names: '',
                    ro_is_lock: 1,
                    start_time: '',
                    end_time: '',
                    reserve_rules_id: '',
                    reserve_type: '',
                    reserve_money: ''
                },
                disabled: false,
                theme_list: [], //主题列表
                rules: {
                    user_name: [
                        {required: true, message: '请输入会员名称', trigger: 'xxx'}
                    ],
                    phone: [
                        {required: true, message: '请输入会员手机号', trigger: 'xxx'}
                    ],
                    staff_name: [
                        {required: true, message: '请输入或选择订台人', trigger: 'xxx'}
                    ],
                }
            },
            desk_config: {
                title: '提示',
                is_show: false
            },
            con_config: {
                type: 0, //0 转台 1 换台 2 并台
                is_show: false
            },
            select_list: [], //台位列表（用于转台换台并台等）
            desk_record_list: [], //台位操作轨迹
            current_desk_info: null
        }
    },
    mounted() {

    },
    watch: {
        desk_item: {
            async handler(newVal) {
                if (newVal) {
                    this.setTopBoxHeight()
                    this.getRoomDetail()
                }
            },
            immediate: true,
            deep: true
        },
        'popup_config.is_show': {
            handler(newVal) {
                if (!newVal) { //关闭窗口
                    this.popup_config.ruleForm.staff_id = ''
                    this.popup_config.disabledStaff = false
                    this.popup_config.disabledMember = false
                }
            },
            deep: true
        }
    },
    methods: {
        /**
         * 查台位详情
         */
        getRoomDetail(config = {}) {
            let id = config.id || this.desk_item.id //优先查询传进来的台位id
            return new Promise((resolve, reject) => {
                cashierApi.roomShow({
                    id,
                }).then(res => {
                    if (!config.id) {
                        this.current_desk_info = res.data
                    }
                    this.$emit('returnDeskInfo', res.data)
                    this.recordGetRecordLog()
                    resolve()
                })
            })
        },
        /**
         * 清理订台人信息(更换订台人)
         */
        clearStaff() {
            this.popup_config.ruleForm.staff_name = ''
            this.popup_config.ruleForm.staff_id = 0
            this.popup_config.disabledStaff = false
        },
        /**
         * 复台
         */
        recoveryAgreeFun(item) {
            cashierApi.recoveryRoom({
                open_room_id: item.id
            }).then(res => {
                this.$message({
                    type: 'success',
                    message: '操作成功!'
                })
                this.getRoomDetail()
            })
        },
        /**
         * 选择订台人回调 - 确认
         */
        staffAgreeFun(item) {
            if (this.staff_dialog_config.type == 1) { // 选择订台人

                if (item.user_department) {
                    this.popup_config.ruleForm.staff_name = item.realname + ' - ' + item.user_department
                } else {
                    this.popup_config.ruleForm.staff_name = item.realname
                }

                this.popup_config.ruleForm.staff_id = item.id
                this.popup_config.disabledStaff = true

            } else if (this.staff_dialog_config.type == 2) { // 更换订台人
                this.openRoomChangeStaff(item)
            }
        },
        /**
         * 修改会员
         */
        openRoomChangeMember(item) {
            if (this.current_desk_info.ro_status == 3) { //是否是预定
                cashierApi.scheduleChangeMember({
                    station_reserve_id: this.current_desk_info.reserve.station_reserve_id,
                    member_id: item.id
                }).then(res => {
                    this.$message.success('修改成功')
                    this.getRoomDetail()
                })
            } else {
                cashierApi.openRoomChangeMember({
                    open_room_id: this.current_desk_info.open_room_id,
                    member_id: item.id
                }).then(res => {
                    this.$message.success('修改成功')
                    this.getRoomDetail()
                })
            }
        },
        /**
         * 修改订台人
         */
        openRoomChangeStaff(item) {
            if (this.current_desk_info.ro_status == 3) { //是否是预定
                cashierApi.scheduleChangeStaff({
                    station_reserve_id: this.current_desk_info.reserve.station_reserve_id,
                    sales_id: item.id,
                    staff_name: item.realname
                }).then(res => {
                    this.$message.success('修改成功')
                    this.getRoomDetail()
                })
            } else {
                cashierApi.openRoomChangeStaff({
                    open_room_id: this.current_desk_info.open_room_id,
                    staff_id: item.id,
                    staff_name: item.realname
                }).then(res => {
                    this.$message.success('修改成功')
                    this.getRoomDetail()
                })
            }
        },
        /**
         * 打开订台人列表
         */
        openChangeStaffPopup(type) {
            this.staff_dialog_config.is_show = true
            this.staff_dialog_config.type = type
        },
        /**
         * 计算距离顶部高度
         */
        setTopBoxHeight() {
            let nav_height = document.getElementById('nav-bar-component').clientHeight || 0
            this.top_height = nav_height
        },
        /**
         * 颜色处理
         */
        bgColor(record_type) {
            switch (record_type) {
                case 1:
                    return 'background: rgb(5, 129, 254)'
                case 2:
                    return 'background: rgb(195, 105, 105)'
                case 3:
                    return 'background: rosybrown'
                case 4:
                    return 'background: rgb(202, 166, 63)'
                case 5:
                    return 'background: rgb(77, 184, 136)'
                case 6:
                    return 'background: rgb(81, 49, 142)'
                case 7:
                    return 'background: rgb(49, 189, 180)'
                case 8:
                    return 'background: #4a5159'
                case 9:
                    return 'background: slateblue'
                case 10:
                    return 'background: rgb(185, 114, 228)'
                case 11:
                    return 'background: rgb(0, 198, 120)'
                default:
                    return 'background: rgb(0, 198, 120)'
            }
        },
        /**
         * 是否可以预定，可以则resolve
         */
        scheduleReserveStatus() {
            return new Promise((resolve, reject) => {
                cashierApi.scheduleReserveStatus().then(res => {
                    resolve()
                })
            })
        },
        /**
         * 通过手机号获取用户信息
         */
        clientUserInfo(config = {}) {
            return cashierApi.clientUserInfo({phone: config.phone})
        },
        /**
         * 直接开台或直接订台的用户信息
         */
        getDirectDefaultUserInfo() {
            return new Promise(async (resolve, reject) => {
                let user_info = {
                    u_id: 0,
                    name: '散客',
                    phone: 13000000000,
                    sex: 1,
                }

                await this.clientUserInfo({
                    phone: user_info.phone
                }).then(res => {
                    if (res && res.name) {
                        user_info.u_id = res.member_id
                        user_info.phone = res.phone
                        user_info.sex = res.sex || 1
                    }
                    resolve(user_info)
                })
            })
        },
        /**
         * 直接开台
         */
        directKt() {
            this.$confirm(`确定要直接开台吗?`, '开台', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let user_info = await this.getDirectDefaultUserInfo()
                let params = {
                    u_id: user_info.u_id,
                    user_name: user_info.name,
                    sex: user_info.sex,
                    phone: user_info.phone,
                    room_id: this.current_desk_info.id,
                    room_name: this.current_desk_info.ro_room_name,
                    open_room_time: customFormatFullTime(new Date(), 'YYYY-MM-DD h:m'),
                    open_room_type: 1,
                    people_number: 1,
                    staff_id: 1,
                    staff_name: '系统管理员',
                    theme_id: 0,
                    theme_name: '',
                    status: 1, //状态 1正常 2转台 3 换台 4并台 5完结
                    remarks: '',
                    ro_is_lock: 1,
                    member_initials: 'XTGLY' //会员姓名快捷码
                }
                this.openRoomCreate(params)
            })
        },
        /**
         * 直接订台
         */
        directDt() {
            this.$confirm(`确定要直接订台吗?`, '开台', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let user_info = await this.getDirectDefaultUserInfo()
                let rule_info = await this.scheduleRulesInfo({id: this.current_desk_info.id})
                let params = {
                    member_id: user_info.u_id,
                    name: user_info.name,
                    phone: user_info.phone,
                    sex: user_info.sex,
                    reserve_type: rule_info.reserve_type || 0,
                    reserve_money: rule_info.reserve_money || 0,
                    arrival_time: customFormatFullTime(this.computedDefaultTime(), 'YYYY-MM-DD h:m'),
                    sales_id: 1,
                    sales_name: "系统管理员",
                    reserve_people_num: 1,
                    theme_id: 0,
                    station_id: this.current_desk_info.id,
                    station_name: this.current_desk_info.ro_room_name,
                    station_region_id: this.current_desk_info.ro_region_id,
                    station_region_name: this.current_desk_info.ro_region_name,
                    remarks: "",
                    source: 3,
                    customer_source: 1,
                    rules_id: rule_info.reserve_rules_id || 0,
                    member_initials: "XTGLY" //会员姓名快捷码
                }
                this.scheduleAddInfo(params)
            })

        },
        /**
         * type 1预定 0开台
         */
        async openRunningWaterPopup(type) {
            if (type === 1) {
                await this.scheduleReserveStatus()
            }

            this.popup_config.is_show = true

            await this.roomTheme()

            // 查台位信息
            this.$nextTick(() => {
                let people_number = this.current_desk_info.ro_capacity_people_number.split('-')
                this.popup_config.ruleForm.people_number = people_number[0]
                this.popup_config.type = type

                if (this.popup_config.type == 0) {

                    this.popup_config.title = '开台'
                    //预定后开台回显
                    if (this.current_desk_info.reserve) {
                        // let open_room_time = this.current_desk_info.reserve.arrival_time.split(' ')[1] //获取时间、不包括年月日
                        this.popup_config.ruleForm.u_id = this.current_desk_info.reserve.member_id
                        this.popup_config.ruleForm.user_name = this.current_desk_info.reserve.name
                        this.popup_config.ruleForm.phone = this.current_desk_info.reserve.phone
                        this.popup_config.ruleForm.sex = this.current_desk_info.reserve.sex
                        this.popup_config.ruleForm.open_room_time = customFormatFullTime(this.current_desk_info.reserve.arrival_time, 'YYYY-MM-DD h:m')
                        this.popup_config.ruleForm.people_number = this.current_desk_info.reserve.reserve_people_num
                        this.popup_config.ruleForm.staff_id = this.current_desk_info.reserve.sales_id
                        this.popup_config.ruleForm.staff_name = this.current_desk_info.reserve.sales_name
                        this.popup_config.ruleForm.theme_id = this.current_desk_info.reserve.theme_id ? String(this.current_desk_info.reserve.theme_id) : ''
                        this.popup_config.ruleForm.remarks = this.current_desk_info.reserve.remarks
                        this.popup_config.disabledStaff = true
                        this.popup_config.disabledMember = true
                    } else {
                        this.popup_config.ruleForm.open_room_time = customFormatFullTime(new Date(), 'YYYY-MM-DD h:m')
                    }
                } else if (this.popup_config.type == 1) {

                    this.popup_config.title = '预定'
                    this.setDefaultTime()
                    this.scheduleRulesInfo({
                        id: this.current_desk_info.id
                    }).then(res => {
                        this.popup_config.ruleForm.reserve_rules_id = res.reserve_rules_id || 0
                        this.popup_config.ruleForm.reserve_type = res.reserve_type || 0
                        this.popup_config.ruleForm.reserve_money = res.reserve_money || 0
                        this.popup_config.ruleForm.start_time = res.start_time
                        this.popup_config.ruleForm.end_time = res.end_time
                    })
                }
            })
        },

        /**
         * 获取台位预定设置
         */
        scheduleRulesInfo(params) {
            return new Promise((resolve, reject) => {
                cashierApi.scheduleRulesInfo(params).then(res => {
                    resolve(res.data)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        /**
         * 设置默认时间，如果在start_stamp和end_stamp时间段内的，默认当前时间，如果不在start_stamp和end_stamp时间段内，推迟半个小时
         */
        setDefaultTime() {
            this.popup_config.ruleForm.open_room_time = this.computedDefaultTime()
        },
        /**
         * 计算默认时间
         */
        computedDefaultTime() {
            let format_date = customFormatFullTime(new Date(), 'YYYY-MM-DD')
            // 预定获取当前时间段（目前前台固定这个时间段）
            let start_stamp = new Date(`${format_date} 06:00`).getTime()
            let end_stamp = new Date(`${format_date} 22:00`).getTime()

            let current_stamp = new Date().getTime()

            if (current_stamp >= start_stamp && current_stamp <= end_stamp) {
                return customFormatFullTime(end_stamp, 'YYYY-MM-DD h:m')
            } else {
                // 未到开始时间（小时到了分钟未到） 当前时间增加半小时
                return new Date(new Date().getTime() + (1000 * 60 * 30))
            }
        },
        /**
         * 确认开台/预定
         */
        saveHandle() {
            let people_number = this.current_desk_info.ro_capacity_people_number.split('-')
            let phone = /^1[0-9]{10}$/ //电话号码
            let member = /^(?:0|[1-9][0-9]?|10000000)$/  //整数
            if (!this.popup_config.ruleForm.user_name) {
                this.$message.warning('请输入或选择会员名称')
                return
            } else if (!this.popup_config.ruleForm.phone) {
                this.$message.warning('请输入手机号码')
                return
            } else if (!phone.test(this.popup_config.ruleForm.phone)) {
                this.$message.warning('请输入正确的手机号码')
                return
            } else if (!this.popup_config.ruleForm.staff_name || !this.popup_config.ruleForm.staff_id) {
                this.$message.warning('请选择正确的订台人')
                return
            } else if (!this.popup_config.ruleForm.people_number) {
                this.$message.warning('请输入人数')
                return
            } else if (!member.test(this.popup_config.ruleForm.people_number)) {
                this.$message.warning('请输入整数')
                return
            } else if (parseInt(this.popup_config.ruleForm.people_number) < parseInt(people_number[0])) {
                this.$message.warning(`人数不能小于${parseInt(people_number[0])}人`)
                return
            } else if (parseInt(this.popup_config.ruleForm.people_number) > parseInt(people_number[1])) {
                this.$message.warning(`人数不能大于${parseInt(people_number[1])}人`)
                return
            }

            if (this.popup_config.type == 0) { //开台
                this.openRoomBeforeCreate()
            } else if (this.popup_config.type == 1) { //订台
                this.reserveRoomBeforeCreate()
            }

        },
        /**
         * 订台前处理数据格式
         */
        reserveRoomBeforeCreate() {

            if (!this.popup_config.ruleForm.open_room_time) {
                this.$message.warning('请输入日期')
                return
            }

            let config = {
                member_id: this.popup_config.ruleForm.u_id || 0,
                name: this.popup_config.ruleForm.user_name,
                phone: this.popup_config.ruleForm.phone,
                sex: this.popup_config.ruleForm.sex,
                reserve_type: this.popup_config.ruleForm.reserve_type,
                reserve_money: this.popup_config.ruleForm.reserve_money,
                arrival_time: customFormatFullTime(this.popup_config.ruleForm.open_room_time, 'YYYY-MM-DD h:m'),
                sales_id: this.popup_config.ruleForm.staff_id || 0,
                sales_name: this.popup_config.ruleForm.staff_name,
                reserve_people_num: this.popup_config.ruleForm.people_number,
                theme_id: this.popup_config.ruleForm.theme_id || 0,
                station_id: this.current_desk_info.id,
                station_name: this.current_desk_info.ro_room_name,
                station_region_id: this.current_desk_info.ro_region_id,
                station_region_name: this.current_desk_info.ro_region_name,
                remarks: this.popup_config.ruleForm.remarks,
                source: 3,
                customer_source: 1,
                rules_id: this.popup_config.ruleForm.reserve_rules_id,
                member_initials: this.getMemberInitials() //会员姓名快捷码
            }

            this.scheduleAddInfo(config)
        },
        /**
         * 开台前处理数据格式
         */
        openRoomBeforeCreate() {

            if (!this.popup_config.ruleForm.open_room_time) {
                this.$message.warning('请输入日期时间')
                return
            }

            let params = {
                u_id: this.popup_config.ruleForm.u_id || 0,
                user_name: this.popup_config.ruleForm.user_name,
                sex: this.popup_config.ruleForm.sex,
                phone: this.popup_config.ruleForm.phone,
                room_id: this.current_desk_info.id,
                room_name: this.current_desk_info.ro_room_name,
                open_room_time: this.popup_config.ruleForm.open_room_time,
                open_room_type: 1,
                people_number: this.popup_config.ruleForm.people_number,
                staff_id: this.popup_config.ruleForm.staff_id || 0,
                staff_name: this.popup_config.ruleForm.staff_name,
                theme_id: this.popup_config.ruleForm.theme_id || 0,
                theme_name: this.popup_config.ruleForm.theme_name,
                status: this.popup_config.ruleForm.status,
                remarks: this.popup_config.ruleForm.remarks,
                ro_is_lock: this.popup_config.ruleForm.ro_is_lock,
                member_initials: this.getMemberInitials() //会员姓名快捷码
            }

            // 检查当前是否是预定开台
            if (this.current_desk_info.ro_status == 3 && this.current_desk_info.reserve) {
                params['status'] = 3
                params['reserve_num'] = this.current_desk_info.reserve.reserve_num
                params['station_reserve_id'] = this.current_desk_info.reserve.station_reserve_id
            }

            this.openRoomCreate(params)
        },
        /**
         * 预定接口
         */
        scheduleAddInfo(params) {
            cashierApi.scheduleAddInfo(params).then(res => {
                this.$message.success('预定成功')
                this.resetForm()
                this.getRoomDetail()
            })
        },
        /**
         * 开台接口
         */
        openRoomCreate(params) {
            cashierApi.openRoomCreate(params).then(res => {
                this.$message.success('开台成功')
                this.resetForm()
                this.getRoomDetail()
            })
        },
        /**
         * 通过会员姓名获取快捷码
         */
        getMemberInitials() {
            let member_initials = '' // 会员姓名快捷码(一般是新用户才创建，这里正常给)
            if (this.popup_config.ruleForm.user_name && this.popup_config.ruleForm.user_name.trim()) {
                member_initials = pinyin.getCamelChars(this.popup_config.ruleForm.user_name)
            }
            return member_initials
        },
        /**
         * 选择会员 - 点击按钮
         */
        openChangeMemberPopup(type) {
            this.member_dialog_config.is_show = true
            this.member_dialog_config.type = type
        },
        /**
         * 选择散客，散客手机号默认 13800000000
         */
        checkSanke() {
            cashierApi.clientUserInfo({phone: '13000000000'}).then(res => {
                this.popup_config.ruleForm.user_name = res.data.name
                this.popup_config.ruleForm.phone = res.data.phone
                this.popup_config.ruleForm.sex = res.data.sex
                this.popup_config.ruleForm.u_id = res.data.member_id
                this.popup_config.disabledMember = true
            })
        },
        /**
         * 获取主题列表
         */
        roomTheme() {
            return new Promise((resolve, reject) => {
                cashierApi.roomTheme().then(res => {
                    let theme_list = []
                    for (let i in res.data) {
                        theme_list.push({
                            id: i,
                            label: res.data[i]
                        })
                    }
                    this.popup_config.theme_list = theme_list
                    resolve()
                })
            })
        },
        /**
         * 清空开台或预定信息(弹窗内的信息)
         */
        resetForm() {
            this.$refs['ruleForm'] && this.$refs['ruleForm'].resetFields()
            this.popup_config.is_show = false
        },
        /**
         * 模糊搜索 - 选择订台人
         */
        handleSelect(item) {
            this.popup_config.ruleForm.staff_name = item.name
            this.popup_config.ruleForm.staff_id = item.id
            this.popup_config.ruleForm.grade_name = item.grade_name
            if (item.grade_name) {
                this.popup_config.ruleForm.grade_names = `${item.name}-${item.grade_name}`
            } else {
                this.popup_config.ruleForm.grade_names = item.name + '-' + '普通会员'
            }
            this.popup_config.disabledStaff = true
        },
        /**
         * 输入- 模糊查询订台人
         */
        async querySearch(queryString, cb) {
            if (!queryString.trim()) {
                return;
            }
            let results = []
            await this.memberVagueUserInfo(queryString).then(list => {
                for (let i in list) {
                    list[i]['name'] = list[i].realname + ' - ' + list[i].department_name
                }
                results = list
            })
            cb(results)
        },
        /**
         * 模糊查询员工部门
         */
        memberVagueUserInfo(value) {
            return new Promise((resolve, reject) => {
                memberApi.memberVagueUserInfo({
                    realname: value
                }, {
                    close_loading: true
                }).then(res => {
                    resolve(res.data)
                })
            })
        },
        /**
         * 选择会员 - 回显
         */
        memberAgreeFun(item) {
            if (this.member_dialog_config.type == 1) {  //选择会员
                this.popup_config.ruleForm.user_name = item.name
                this.popup_config.ruleForm.phone = item.phone
                this.popup_config.ruleForm.sex = item.sex
                this.popup_config.ruleForm.u_id = item.id
                this.popup_config.disabledMember = true
            } else if (this.member_dialog_config.type == 2) {  //更换会员
                this.openRoomChangeMember(item)
            }
        },
        /**
         * 台位列表
         */
        getDeskList(type) {
            this.con_config.type = type
            let list = []
            for (let i in this.desktop_list) {
                for (let b in this.desktop_list[i].list) {
                    list.push(this.desktop_list[i].list[b])
                }
            }
            this.select_list = list
            this.con_config.is_show = true
        },
        /**
         * 开台转台/预定转台
         */
        forwardHandle(exempt) {
            if (this.current_desk_info.ro_status == 3) {
                // 预定转台
                cashierApi.reserveTurnRoom({
                    id: this.current_desk_info.reserve.station_reserve_id,
                    room_id: this.forward_config.id,
                    is_exempt: exempt
                }).then(res => {
                    this.$message.success('转台成功')
                    this.cancelFun1()
                    this.getRoomDetail()
                    let stop = setTimeout(() => {
                        this.getRoomDetail({
                            id: this.forward_config.id
                        })
                        clearTimeout(stop)
                    }, 1000)
                })
            } else {
                // 开台转台
                cashierApi.openRoomTurnRoom({
                    id: this.current_desk_info.open_room_id,
                    room_id: this.forward_config.id,
                    is_exempt: exempt
                }).then(res => {
                    this.$message.success('转台成功')
                    this.cancelFun1()
                    this.getRoomDetail()
                    let stop = setTimeout(() => {
                        this.getRoomDetail({
                            id: this.forward_config.id
                        })
                        clearTimeout(stop)
                    }, 1000)
                })
            }
        },
        cancelFun1() {
            // this.$refs['selectForm'].resetFields()
            this.con_config.is_show = false
            this.desk_config.is_show = false
        },
        conConfigAgreeFun(item) {
            if (!item.id) {
                this.$message.warning('台位不能为空')
                return
            }
            if (this.con_config.type == 0) {
                // 转台
                this.forward_config.id = item.id
                if (this.current_desk_info.ro_status == 3) {
                    // 预定转台 不需要打开弹框
                    this.forwardHandle(1)
                } else {
                    // 开台转台，打开弹框
                    this.desk_config.is_show = true
                }
            } else if (this.con_config.type == 1) {
                this.changeRoom(item)
            } else if (this.con_config.type == 2) {
                /**
                 * 并台
                 */
                cashierApi.openRoomAndRoom({
                    aid: this.current_desk_info.open_room_id,
                    bid: item.open_room_id,
                }).then(async res => {
                    this.$message.success('并台成功')
                    await this.getRoomDetail()
                    await this.getRoomDetail({
                        id: item.open_room_id
                    })
                    this.cancelFun1()
                })
            }

        },
        /**
         * 开台换台/预定换台
         */
        changeRoom(item) {
            if (this.current_desk_info.ro_status == 3) {
                // 预定换台
                cashierApi.reserveChangeRoom({
                    main_id: this.current_desk_info.reserve.station_reserve_id,
                    from_id: item.reserve.station_reserve_id,
                }).then(async res => {
                    this.$message.success('换台成功')
                    await this.getRoomDetail()
                    await this.getRoomDetail({
                        id: item.id
                    })
                    this.cancelFun1()
                })
            } else {
                // 开台换台
                cashierApi.openRoomChangeRoom({
                    aid: this.current_desk_info.open_room_id,
                    bid: item.open_room_id,
                }).then(async res => {
                    this.$message.success('换台成功')
                    await this.getRoomDetail()
                    await this.getRoomDetail({
                        id: item.id
                    })
                    this.cancelFun1()
                })
            }
        },
        /**
         * 台位操作信息
         */
        recordGetRecordLog() {
            if (this.current_desk_info.open_room_bill) {
                cashierApi.recordGetRecordLog({
                    open_room_bill: this.current_desk_info.open_room_bill
                }).then(res => {
                    res.data.map(o => {
                        if ((o.record_type === 5 || o.record_type === 10 || o.record_type === 6 || o.record_type === 16) && o.record_info) {
                            o.record_info = JSON.parse(o.record_info)
                            o.record_info['status_name'] = this.returnStatusName(o.record_info)
                            o.record_info['refund_status_name'] = this.returnRefundStatusName(o.record_info)
                            // console.log('o.record_info',o)
                        }
                    })
                    this.desk_record_list = res.data
                })
            } else {
                this.desk_record_list = []
            }
        },
        /**
         * 复台
         */
        recoveryFun() {
            this.recovery_config.room_id = this.current_desk_info.id
            this.recovery_config.is_show = true
        },
        /**
         * 锁台
         */
        lockFun() {
            this.$confirm(`是否确认将台位${this.current_desk_info.ro_room_name}锁定?`, '台位锁定', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                cashierApi.roomLockRoom({
                    id: this.current_desk_info.id
                }).then(res => {
                    this.$message({
                        type: 'success',
                        message: '操作成功!'
                    })
                    this.getRoomDetail()
                })
            })
        },
        /**
         * 解锁
         */
        deblockFun() {
            this.$confirm(`是否确认将台位${this.current_desk_info.ro_room_name}解锁?`, '台位解锁', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                cashierApi.roomUnLocking({
                    id: this.current_desk_info.id
                }).then(res => {
                    this.$message({
                        type: 'success',
                        message: '操作成功!'
                    })
                    this.getRoomDetail()
                })
            })
        },
        /**
         * 清台
         */
        cleanFun() {
            this.$confirm(`是否确认将台位${this.current_desk_info.ro_room_name}清台?`, '清台', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                cashierApi.roomClearRoom({
                    id: this.current_desk_info.id
                }).then(res => {
                    this.$message({
                        type: 'success',
                        message: '操作成功!'
                    })
                    this.getRoomDetail()
                })
            })
        },
        /**
         * 置空台
         */
        clearReserveFun() {
            this.$confirm(`是否确认将台位${this.current_desk_info.ro_room_name}清台?`, '清台', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                cashierApi.roomClearReserve({
                    id: this.current_desk_info.id,
                    uid: this.current_desk_info.reserve.member_id,
                    sales_id: this.current_desk_info.reserve.sales_id,
                    time: this.current_desk_info.reserve.arrival_time,
                    station_reserve_id: this.current_desk_info.reserve.station_reserve_id
                }).then(res => {
                    this.$message({
                        type: 'success',
                        message: '操作成功!'
                    })
                    this.getRoomDetail()
                })
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.desktop-info-component {
    position: fixed;
    right: 0;
    top: 215px;
    bottom: 0;
    background: white;
    overflow: scroll;
    width: 300px;
    z-index: 12;
    border-left: 1px solid rgba(200, 200, 200, .4);

    .nav-list {
        background: rgb(224, 227, 234);

        .nav-item {
            display: inline-block;
            padding: 10px 20px;
            background: white;
            color: rgb(56, 148, 255);
            border-right: 1px solid rgba(200, 200, 200, .4);
        }
    }

    .unline {
        color: #3894ff;
        margin-left: 10px;
        font-size: 12px;
        cursor: pointer;
    }

    .start-desk-box {
        // width:300px;
        .input {
            width: 200px;
        }

        .check-member-btn {
            margin-left: 20px;

            &.clear {
                color: rgb(179, 179, 179);
            }
        }
    }

    .info-box {
        .info {
            &.c1 {
                background: rgb(229, 255, 236);
                padding: 20px;
            }

            &.c2 {
                padding: 20px;
                background: rgb(224, 227, 234);
            }

            &.c3 {
                padding: 20px;
                background: rgb(255, 239, 193);
            }

            .info-top {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 10px;

                .desktop-number {
                    font-size: 14px;
                    font-weight: bold;
                }

                .time {
                }
            }

            .info-list {
                font-size: 12px;
                word-break: break-all;

                .vip-box {
                    margin-bottom: 6px;

                    .vip-label {
                        color: #fff;
                        border-radius: 50px;
                        padding: 2px 8px;
                        background-color: red;
                        line-height: 1;
                        font-size: 10px;
                    }
                }

                .item {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 6px;
                    line-height: 1.2;

                    .left {
                        &.big-text {
                            font-size: 13px;
                        }

                        .done-text {
                            color: rgb(2, 198, 120);
                        }
                    }

                    .right {
                    }
                }

            }
        }

        .step-box {
            margin: 30px 0px 0px 20px;
            overflow: scroll;
            max-height: 50vh;

            &::-webkit-scrollbar {
                display: none;
            }

            .step-item {
                padding: 10px;
                color: white;
                width: 200px;
                margin-bottom: 20px;
                border-radius: 3px;

                .top {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                .content {

                }

                .goods-item {
                    margin: 10px 0px;
                    padding: 10px;
                    border-radius: 6px;
                    overflow: hidden;
                    background-color: white;
                    color: rgb(100, 100, 100);
                }
            }
        }

        .info-control {
            margin: 20px 0px 20px 16px;

            .clear-btn {
                width: 87%;
            }

            .btn-box {
                // margin: 10px 0px;
            }
        }

        .control-btn {
            margin: 4px 10px;
        }
    }
}

.list-to-list-component {
    .search_input {
        width: 300px;
    }

    .cate-list {
        .cate-item {
            padding: 10px 10px;
            color: rgb(1, 108, 233);
            cursor: pointer;

            &.active {
                background: rgba(1, 108, 233, 0.15);
            }
        }
    }

    .card-item {
        & /deep/ .el-card__body {
            height: 400px;
        }
    }

    .table-list {
        max-height: 300px;
        overflow-y: scroll;

        &.right {
            max-height: 350px;
            width: 100%;
        }
    }

    .dialog-footer {
        display: block;
        text-align: center;
    }
}

.bottom {
    margin-bottom: 30px;
    line-height: 25px;
}

</style>