<template>
    <div class="recovery-dialog-component">
        <el-dialog
            width="800px"
            :title="dialog_config.title"
            v-model="dialog_config.is_show"
            :show-close="false"
            :close-on-click-modal="false"
            :close-on-press-escape="false">
            <div>
                <table-component class="recovery-table" :table="dialog_config.table" @handleCurrentChange="checkItem">
                    <template #default="data">
                        <div>{{data.scope.row[data.item.prop]}}</div>
                    </template>
                </table-component>
                <div class="pagination-box">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="pagination.page"
                        :page-sizes="pagination.page_sizes"
                        :page-size="pagination.page_size"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="pagination.total">
                    </el-pagination>
                </div>
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="cancelFun">取消</el-button>
                    <el-button type="primary" @click="agreeFun">确认</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>
<script>
import tableComponent from '@/components/table'
import {pubDeskApi} from '@/api'
export default {
    name:'recovery-dialog-component',
    components:{
        tableComponent
    },
    props:{
        is_show:[Boolean],
        room_id:[String,Boolean]
    },
    watch:{
        is_show:{
            handler(newVal){
                console.log('newVal',newVal)
                this.dialog_config.is_show = newVal
                if(newVal){
                    this.getList()
                }
            },
            immediate:true,
            deep:true
        },
        'dialog_config.is_show':{
            handler(newVal){
                this.$emit('update:is_show',newVal)
            },
            deep:true
        }
    },
    data(){
        return{
            dialog_config:{
                title:'选择开台记录',
                is_show:false,
                form:{},
                table:{
                    highlight_current_row:true,
                    column_key:[
                        {
                            prop:'open_room_bill',
                            label:'开台编号'
                        },
                        {
                            prop:'user_name',
                            label:'客户姓名'
                        },
                        {
                            prop:'staff_name',
                            label:'订台人'
                        },
                        {
                            prop:'open_room_time',
                            label:'开台时间'
                        },
                    ],
                    list:[]
                }
            },
            pagination:{
                page:1,
                page_size:10,
                total:0,
                page_sizes:[10,20,30]
            },
            check_item:null
        }
    },
    methods:{
        /**
         * 获取开台记录列表
         */
        getList(){
            pubDeskApi.openRoomShowList({
                room_id:this.room_id,
                page:this.pagination.page,
                pageSize:this.pagination.page_size,
            }).then(res=>{
                this.pagination.total = res.data.total
                this.dialog_config.table.list = res.data.list
            })
        },
        checkItem(item,preItem){
            this.check_item = item
        },
        cancelFun(){
            this.dialog_config.table.list = []
            this.check_item = null
            this.dialog_config.is_show = false
        },
        agreeFun(){
            if(!this.check_item){
                this.$message.warning('请先选择订台人')
                return;
            }
            this.$emit('agree',this.check_item)
            this.cancelFun()
        },
        handleSizeChange(page_size){
            this.pagination.page_size = page_size
            this.getList()
        },
        handleCurrentChange(page){
            this.pagination.page = page
            this.getList()
        }
    }
}
</script>
<style lang="scss" scoped>
.recovery-dialog-component{
    .recovery-table{
        & /deep/ tr{
            cursor: pointer;
            &.current-row{
                td{
                    background-color: rgb(64, 158, 255);
                    color: white;
                }
            }
        }
    }
}
</style>