<template>
    <div class="cashier-page">
        <div class="top-box" id="top-box">
            <div class="top-nav">
                <div class="right">
                    <el-button @click="clickReleaseRank" class="bg_color">实时排行</el-button>
                    <el-button @click="openSpt" class="bg_color">沙盘图</el-button>
                    <el-select class="select-box" v-model="control_config.id" placeholder="请选择">
                        <el-option
                            v-for="item of control_config.options"
                            :key="item.id"
                            :label="item.label"
                            :value="item.id">
                        </el-option>
                    </el-select>
                    <div class="search-box">
                        <el-input
                            placeholder="请输入台位名称"
                            suffix-icon="el-icon-search"
                            clearable
                            v-model="search_config.search_text"
                            @change="searchTextFun">
                        </el-input>
                    </div>
                    <div class="picker-box">
                        <el-date-picker
                            v-model="search_config.picker_check_time"
                            type="date"
                            :clearable="false"
                            placeholder="选择时间"
                            :disabled-date="disabledDate"
                            @change="changeTimeFun">
                        </el-date-picker>
                    </div>
                </div>
                <div class="left">
                    <el-radio-group v-model="type_popup_config.radio_check" @change="typeChange(type_popup_config.radio_check)">
                        <el-radio-button v-for="(item,index) of type_popup_config.radio_list" :key="index" :label="item.id" >{{item.r_region_name}}</el-radio-button>
                    </el-radio-group>
                    <el-button class="global-ml-10 refresh-btn" @click="refreshDesktop">台位刷新</el-button>
                </div>
            </div>
            <div class="top-hint-box top-hint-box-border">
                <span class="hint-text">台位动态：共{{total_desk_num}}张</span>
                <div class="hint-flex">
                    <div class="hint-item" @click="afterDesktopStatusFun(1)">
                        <span class="desltop-icon desltop-icon1 fontSize">空</span>
                        <span>{{desk_status_info.emptyRoom}}张</span>
                    </div>
                    <div class="hint-item" @click="afterDesktopStatusFun(3)">
                        <span class="desltop-icon desltop-icon2 fontSize">预</span>
                        <span>{{desk_status_info.reserveRoom}}张</span>
                    </div>
                    <div class="hint-item" @click="afterDesktopStatusFun(2)">
                        <span class="desltop-icon desltop-icon3 fontSize">开</span>
                        <span>{{desk_status_info.ongoingRoom}}张</span>
                    </div>
                    <div class="hint-item" @click="afterDesktopStatusFun(4)">
                        <span class="desltop-icon desltop-icon4 fontSize">后</span>
                        <span>{{desk_status_info.posteriorKnot}}张</span>
                    </div>
                    <div class="hint-item" @click="afterDesktopStatusFun(0,2)">
                        <span class="desltop-icon desltop-icon5 fontSize">锁</span>
                        <span>{{desk_status_info.lockRoom}}张</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-box">
            <div class="left-box is-desktop">
                <div v-for="(region_item,region_index) in desktop_list" :key="region_index">
                    <div class="top-hint-box">
                        <!-- class=hidden_id标签用于滚动定位 -->
                        <div class="hidden_id" :id="region_item.ro_region_id"></div>
                        <span class="hint-text big-hint-text">{{region_item.ro_region_name}}</span>
                        <div class="hint-flex">
                            <div class="hint-item hint-left" :class="{active:activeShow(region_item,1)}" @click="setDesktopStatus(region_item,1)">
                                <span class="desltop-icon desltop-icon1 fontSize">空</span>
                                <span>{{region_item.desktop_info.emptyRoom}}张</span>
                            </div>
                            <div class="hint-item hint-left" :class="{active:activeShow(region_item,3)}" @click="setDesktopStatus(region_item,3)">
                                <span class="desltop-icon desltop-icon2 fontSize">预</span>
                                <span>{{region_item.desktop_info.reserveRoom}}张</span>
                            </div>
                            <div class="hint-item hint-left" :class="{active:activeShow(region_item,2)}" @click="setDesktopStatus(region_item,2)">
                                <span class="desltop-icon desltop-icon3 fontSize">开</span>
                                <span>{{region_item.desktop_info.ongoingRoom}}张</span>
                            </div>
                            <div class="hint-item hint-left" :class="{active:activeShow(region_item,4)}" @click="setDesktopStatus(region_item,4)">
                                <span class="desltop-icon desltop-icon4 fontSize">后</span>
                                <span>{{region_item.desktop_info.posteriorKnot}}张</span>
                            </div>
                            <div class="hint-item hint-left" :class="{active:activeLockShow(region_item)}" @click="setDesktopLock(region_item,2)">
                                <span class="desltop-icon desltop-icon5 fontSize">锁</span>
                                <span>{{region_item.desktop_info.lockRoom}}张</span>
                            </div>
                        </div>
                    </div>
                    <div class="desktop-box">
                        <div>
                            <tmeplate v-for="(item, index) in region_item.list" :key="index">
                                <div class="box-col" :class="deskClassFun(item)" :span="4" v-if="desktopShow(region_item,item)">
                                    <div class="box-disabled">
                                        <span>台位被禁用</span>
                                    </div>
                                    <div @click="openDesktopInfo(item)">
                                        <el-card class="card-item">
                                            <template #header>
                                                <div class="clearfix">
                                                    <span>{{item.ro_room_name}}</span>
                                                    <span class="desltop-icon desltop-icon1 floatright" v-if="item.ro_status == 1 && item.ro_is_lock == 1">空</span>
                                                    <span class="desltop-icon desltop-icon3 floatright" v-if="item.ro_status == 2">开</span>
                                                    <span class="desltop-icon desltop-icon2 floatright" v-if="item.ro_status == 3">预</span>
                                                    <span class="desltop-icon desltop-icon1 floatright" v-if="item.ro_is_lock == 2">锁</span>
                                                    <span class="desltop-icon desltop-icon3 floatright" v-if="item.ro_status == 2 && item.later_pay == 2">后</span>
                                                    <span class="desltop-icon desltop-icon6 floatright" v-if="item.reserve && item.reserve.reserve_status == 5">超时</span>
                                                </div>
                                            </template>
                                            <div class="box-content">
                                                <!-- 空台 start -->
                                                <div v-if="item.ro_status == 1">
                                                    <div>
                                                        <span>类型：</span>
                                                        <span>{{item.ro_pf_type_name}}</span>
                                                    </div>
                                                    <div>
                                                        <span>可容纳{{item.ro_capacity_people_number}}人</span>
                                                    </div>
                                                    <div>
                                                        <span>低消：￥{{item.ro_low_consume || '0.00'}}</span>
                                                    </div>
                                                    <div v-if="item.ro_is_lock == 1" class="margin-center">
                                                        <h2>空台可开</h2>
                                                    </div>
                                                    <div v-if="item.ro_is_lock == 2" class="margin-center">
                                                        <h2>锁台中</h2>
                                                    </div>
                                                </div>
                                                <!-- 空台 end -->

                                                <!-- 开台 start -->
                                                <div v-else-if="item.ro_status == 2">
                                                    <div v-if="item.VIP >= 1" class="vip-box">
                                                        <span class="vip-label">VIP消费</span>
                                                    </div>
                                                    <div>
                                                        <span>{{item.user_name}}&nbsp;{{item.phone}}</span>
                                                    </div>
                                                    <div>
                                                        <span>开台:</span>
                                                        <span>{{item.open_room_time}}</span>
                                                    </div>
                                                    <div>
                                                        <span>订台人:</span>
                                                        <span>{{item.staff_name}}</span>
                                                    </div>
                                                    <div v-if="item.theme_name">
                                                        <span>主题:</span>
                                                        <span>{{item.theme_name}}</span>
                                                    </div>
                                                    <div>
                                                        <span>低消：￥{{item.ro_low_consume || '0.00'}}</span>
                                                    </div>
                                                    <div>
                                                        <span>下单金额：￥{{item.total_price || '0.00'}}</span>
                                                    </div>
                                                    <div>
                                                        <span>赠送金额：￥{{item.room_give_price || '0.00'}}</span>
                                                    </div>
                                                    <div v-if="item.open_frequency">
                                                        <span>开台次数：{{item.open_frequency || '0'}}</span>
                                                    </div>
                                                    <div v-if="item.remarks">
                                                        <span>备注：{{item.remarks}}</span>
                                                    </div>
                                                </div>
                                                <!-- 开台 end -->

                                                <!-- 预定 start -->
                                                <div v-else-if="item.ro_status == 3 && item.reserve">
                                                    <div>
                                                        <span>{{item.reserve.name}}</span>
                                                    </div>
                                                    <div>
                                                        <span>预定号：</span>
                                                        <span>{{item.reserve.reserve_num}}</span>
                                                    </div>
                                                    <div>
                                                        <span>时间:</span>
                                                        <span>{{item.reserve.arrival_time}}</span>
                                                    </div>
                                                    <div>
                                                        <span>订台人:</span>
                                                        <span>{{item.reserve.sales_name}}</span>
                                                    </div>
                                                    <div>
                                                        <span>主题:</span>
                                                        <span>{{item.reserve.theme_name}}</span>
                                                    </div>
                                                    <div>
                                                        <span>定金：￥{{item.ro_low_consume || '0.00'}}</span>
                                                    </div>
                                                </div>
                                                <!-- 预定 end -->
                                            </div>
                                        </el-card>
                                    </div>
                                </div>
                            </tmeplate>
                        </div>
                    </div>
                </div>
            </div>
            
            <!-- 台位右侧 start -->
            <div class="right-box" v-if="desktop_info_config.is_show_desktop">
                <desktop-info-component ref="desktop" :check_type='check_type' :desk_item="desktop_info_config.current_click_item" :desktop_list="desktop_list" @returnDeskInfo="returnDeskInfo"></desktop-info-component>
            </div>
            <!-- 台位右侧 end -->
        </div>

        <!-- 沙盘图 start -->
        <el-drawer
            :title="spt_config.title"
            v-model="spt_config.is_show"
            :direction="spt_config.direction"
            :before-close="handleClose">
            <div class="spt-box">
                <img :src="merchant_info.merchants_sand_table">
            </div>
        </el-drawer>
        <!-- 沙盘图 end -->

        
        <!-- 台位排行 start -->
        <desk-rank-list-component v-model:is_show="rank_config.is_show"></desk-rank-list-component>
        <!-- 台位排行 end -->
        
    </div>
</template>
<script>
import deskRankListComponent from './components/deskRankList'
import desktopInfoComponent from './components/desktopInfo'
import {cashierApi,loginApi} from '@/api'
import {formatFullDate} from '@/assets/js/utils'
import tableComponent from '@/components/table'
import {merchantInfoMixins,defatulDateMixins} from '@/mixins'
export default {
    name:'cashier-page',
    components:{
        deskRankListComponent,
        desktopInfoComponent,
        tableComponent
    },
    mixins:[merchantInfoMixins,defatulDateMixins],
    data(){
        return{
            rank_config:{
                is_show:false,
            },
            control_config:{
                id:'',
                options:[
                    {
                        id:1,
                        label:'一键清低消'
                    },
                    {
                        id:2,
                        label:'一键还原低消'
                    },
                    {
                        id:3,
                        label:'一键清预定'
                    },
                    {
                        id:4,
                        label:'一键清台'
                    },
                ],
            },
            total_desk_num:0, //共多少台位
            spt_config:{
                title:'沙盘图',
                is_show:false,
                direction:'ttb'
            },
            merchant_info:{ // 商家信息
                merchants_sand_table:'' //沙盘图
            },
            desk_status_info:{}, //台位动态信息
            desktop_info_config:{
                is_show_desktop:false,
                current_click_item:null //点击台位的信息
            },
            desktop_list:[], //所有台位列表
            search_config:{ //搜索相关参数
                search_text:'', //台位名称
                picker_check_time:'', //台位时间
            },
            type_popup_config:{ //台位区域列表
                radio_check:0,
                radio_list:[]
            },
            check_type:0, // 1、预定
        }
    },
    mounted(){
        this.setDefaultDate()
        this.regionIndex()
        this.roomIndex()
    },
    watch:{
        'control_config.id':{
            handler(newVal,oldVal){
                if(!newVal){
                    return;
                }
                if(newVal === 1){
                    this.$confirm(`是否一键清低消?`, '一键清低消', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(()=>{
                        this.debaseFun()
                    })
                }else if(newVal === 2){
                    this.$confirm(`是否一键还原低消?`, '一键还原低消', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(()=>{
                        this.reductionFun()
                    })
                }else if(newVal === 3){
                    this.$confirm(`是否一键清预定?`, '一键清预定', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(()=>{
                        this.reserveFun()
                    })
                }else if(newVal === 4){
                    this.$confirm(`是否一键清台?`, '一键清台', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(()=>{
                        this.delFun()
                    })
                }
                this.control_config.id = ''
            },
            deep:true
        },
        'spt_config.is_show':{
            handler(newVal){
                if(newVal){
                    this.$nextTick(()=>{
                        this.merchantSetIndex()
                    })
                }else{
                    this.merchant_info.merchants_sand_table = ''
                }
            },
            deep:true
        }
    },
    methods:{
        /**
         * 台位局部刷新，替换台位列表对应数据
         */
        returnDeskInfo(item){
            for(let a in this.desktop_list){
                if(this.desktop_list[a].ro_region_id == item.ro_region_id){
                    for(let b in this.desktop_list[a].list){
                        if(this.desktop_list[a].list[b].id  == item.id){
                            this.desktop_list[a].list[b] = item
                            this.$forceUpdate()
                            return;
                        }
                    }
                    break;
                }
            }
        },
        /**
         * 台位刷新 - 按钮
         */
        refreshDesktop(){
            this.regionIndex()
            this.roomIndex()
        },
        /**
         * 设置默认营业日时间
         */
        setDefaultDate(){
            this.search_config.picker_check_time = this.setDefaultYYRDate()
        },
        /**
         * 返回台位样式
         */
        deskClassFun(item){
            let value = []
            
            if(item.ro_is_lock == 1){
                value.push(`bg${item.ro_status}`)
            }else{
                value.push(`bg5`)
            }

            // 禁用
            if(item.ro_status == 9){
                value.push('disabled')
            }
            
            // 选中
            if((this.desktop_info_config.current_click_item && this.desktop_info_config.current_click_item.id) === item.id){
                value.push('active')
            }
            return value;
        },
        
        /**
         * 点击实时排行按钮
         */
        clickReleaseRank(){
            this.openReleaseRank()
        },
        /**
         * 打开实时排行弹窗
         */
        openReleaseRank(){
            this.rank_config.is_show = true
        },
        /**
         * 获取商家设置信息
         */
        merchantSetIndex(){
            loginApi.merchantSetIndex().then(res=>{
                for(let i in res.data){
                    if(res.data[i].name === 'merchants_sand_table'){
                        this.merchant_info.merchants_sand_table = res.data[i].value
                        break;
                    }
                }
            })
        },
        /**
         * 点击选择时间 - change触发
         */
        changeTimeFun(val){
            if(val == null){ //未设置时间，重新加载新数据
                this.check_type = 0
                this.roomIndex()
            }else{ 
                this.roomReserveHistory()
                this.check_type = 1
            }
        },
        /**
         * 点击台位状态标识
         */
        activeShow(item,status){
            return (item.current_status_config && item.current_status_config.region_id === item.ro_region_id) && item.current_status_config.status === status
        },
        /**
         * 点击锁台标识
         */
        activeLockShow(item){
            return (item.current_status_config && item.current_status_config.region_id === item.ro_region_id) && item.current_status_config.ro_is_lock === 2
        },
        /**
         * 当前台位是否显示
         */
        desktopShow(region_item,item){
            if(!region_item.current_status_config){
                return true
            }else if(region_item.current_status_config.status === 1 && item.ro_is_lock === 2) {
                return false
            }else{
                return (( !region_item.current_status_config || !region_item.current_status_config.region_id)
                        || (region_item.ro_region_id === region_item.current_status_config.region_id && item.ro_status === region_item.current_status_config.status)
                        || (region_item.ro_region_id === region_item.current_status_config.region_id && item.ro_is_lock === region_item.current_status_config.ro_is_lock))
            }
            
        },
        /**
         * 设置当前区域台位的状态
         */
        setDesktopStatus(item,status){
            if(item['current_status_config'] 
            && (item['current_status_config'].status === status) 
            && (item['current_status_config'].region_id === item.region_id)){
                item['current_status_config'] = {
                    status:0,
                    ro_is_lock:0,
                    region_id:0
                }
            }else{
                item['current_status_config'] = {
                    status,
                    region_id:item.region_id,
                    ro_is_lock:0
                }
            }
        },
        /**
         * 设置当前台位锁台状态
         */
        setDesktopLock(item,ro_is_lock){
            if(item['current_status_config']
            && (item['current_status_config'].ro_is_lock === ro_is_lock) 
            && (item['current_status_config'].region_id === item.region_id)){
                item['current_status_config'] = {
                    status:0,
                    ro_is_lock:0,
                    region_id:0
                }
            }else{
                item['current_status_config'] = {
                    ro_is_lock,
                    region_id:item.region_id,
                    status:0
                }
            }
        },
        afterDesktopStatusFun(status,ro_is_lock){
            for(let i in this.desktop_list){
                if(this.desktop_list[i]['current_status_config']
                && (this.desktop_list[i]['current_status_config'].status === status)
                && (this.desktop_list[i]['current_status_config'].ro_is_lock === ro_is_lock)){
                    this.desktop_list[i]['current_status_config'] = {
                        status:0,
                        ro_is_lock:0,
                        region_id:0
                    }
                }else{
                    this.desktop_list[i]['current_status_config'] = {
                        region_id:i,
                        status,
                        ro_is_lock
                    }
                }
            }
            this.$forceUpdate()
        },
        /**
         * 点击区域 - 滚动到指定id元素位置
         */
        typeChange(index){
            document.getElementById(index).scrollIntoView({behavior:'smooth'})
        },
        /**
         * 保存点击的台位信息
         */
        saveClickDeskInfoFun(item){
            this.desktop_info_config.is_show_desktop = true
            this.desktop_info_config.current_click_item = item
        },
        /**
         * 打开沙盘图
         */
        openSpt(){
            this.spt_config.is_show = true
        },
        /**
         * 关闭沙盘图
         */
        handleClose(){
            this.spt_config.is_show = false
        },
        /**
         * 某台位被点击
         */
        openDesktopInfo(item){
            // if(item.ro_status == 2 && item.view_details != 1){
            //     this.$message.warning(`你无权限查看${item.ro_room_name}台位详情`)
            //     return;
            // }
            
            // if(item.ro_status == 3 && item.reserve.view_reserve_details != 1){
            //     this.$message.warning(`你无权限查看${item.ro_room_name}台位详情`)
            //     return;
            // }
            this.saveClickDeskInfoFun(item)
        },
        /**
         * 搜索台位名称
         */
        searchTextFun(){
            this.roomIndex()
        },
        /**
         * 台位列表
         */
        roomIndex(){
            cashierApi.roomIndex({
                keyword:this.search_config.search_text
            }).then(res=>{
                this.total_desk_num = res.data.total
                this.desk_status_info = res.data.statusArr
                
                let desktop_list = res.data.list
                desktop_list = this.setDesktopNum(desktop_list)
                this.desktop_list = desktop_list
            })
        },
        /**
         * 处理每个区域台位的信息
         */
        setDesktopNum(desktop_list){
            for(let i in desktop_list){
                let desktop_info = {
                    emptyRoom:0,  //空台
                    reserveRoom:0,  //预定
                    ongoingRoom:0,  //开台
                    posteriorKnot:0,  //后结
                    lockRoom:0  //锁台
                }
                for(let b in desktop_list[i].list){
                    if(desktop_list[i].list[b].ro_is_lock == 2){
                        desktop_info.lockRoom += 1
                    }else if(desktop_list[i].list[b].ro_status == 1){
                        desktop_info.emptyRoom += 1
                    }
                    if(desktop_list[i].list[b].ro_status == 2){
                        desktop_info.ongoingRoom += 1
                    }else if(desktop_list[i].list[b].ro_status == 3){
                        desktop_info.reserveRoom += 1
                    }else if(desktop_list[i].list[b].ro_status == 4){
                        desktop_info.posteriorKnot += 1
                    }
                }
                desktop_list[i]['desktop_info'] = desktop_info
            }
            return desktop_list
        },
        /**
         * 搜索预定历史 - 台位列表
         */
        roomReserveHistory(){
            let date = formatFullDate(this.search_config.picker_check_time)
            cashierApi.roomReserveHistory({
                reserve_date:date,
            }).then(res=>{
                this.desktop_list = this.setDesktopNum(res.data)
            })
        },
        /**
         * 禁用之前的日期，不包含今天
         */
        disabledDate(e){
            if(e.getTime() < new Date().getTime() - 8.64e7){
                return true
            }
        },
        /**
         * 台位区域列表
         */
        regionIndex(){
            cashierApi.regionIndex().then(res=>{
                this.type_popup_config.radio_list = res.data.list
                this.type_popup_config.radio_check = res.data.list[0].id
            })
        },
        /**
         * 一键清低消
         */
        debaseFun(){
            cashierApi.roomClearAllLowConsume().then(res=>{
                this.$message.success('操作成功')
                this.roomIndex()
            })
        },
        /**
         * 一键还原低消
         */
        reductionFun(){
            cashierApi.roomRestoreAllLowConsume().then(res=>{
                this.$message.success('操作成功')
                this.roomIndex()
            })
        },
        /**
         * 一键清预定
         */
        reserveFun(){
            cashierApi.roomClearAllReserve().then(res=>{
                this.$message.success('操作成功')
                this.roomIndex()
            })
        },
        /**
         * 一键清台
         */
        delFun(){
            cashierApi.roomClearAllRoom().then(res=>{
                this.$message.success('操作成功')
                this.roomIndex()
            })
        },
    }
}
</script>
<style lang="scss" scoped>
.cashier-page{
    position: relative;
    // background-color: black;
    background-image: url('http://aiyubar-test.oss-cn-shenzhen.aliyuncs.com/7/winestoring/2021/07/09/1625796813399051.png');
    background-size: 100%;
    height: 100%;
    width: 100%;
    background-attachment:fixed;
    &:after{
        content:"";
        display: block;
        clear:both;
    }
    & /deep/ .el-drawer{
        height: 100%!important;
        .el-drawer__body{
            height: 100%!important;
        }
        .spt-box{
            text-align: center;
            height:100%;
            img{
                height: 100%;
            }
        }
    }
    .rank-dialog{
        height:340px;
        overflow-y:scroll;
    }
    .content-box{
        float: left;
        margin-top: 150px;
        // position: relative;
        // z-index:10;
        .left-box{
            .hidden_id{
                position: absolute;
                visibility: hidden;
                top:-220px;
            }
            &.is-desktop{
                margin-right: 300px;
            }
        }
        .right-box{

        }
    }
    .top-box{
        width: 100%;
        position: fixed;
        z-index: 11;
        background-image: url('http://aiyubar-test.oss-cn-shenzhen.aliyuncs.com/7/winestoring/2021/07/09/1625796813399051.png');
        background-size: 100%;
        width: 100%;;
        overflow: hidden;
        .top-nav{
            margin: 10px;
            .refresh-btn{
                background: rgb(74, 82, 89);
                border-color:rgb(74, 82, 89);
                color: white;
            }
            .select-box{
                & /deep/ .el-input__inner{
                    width: 100px;
                    margin-left:10px;
                    background: rgb(74, 82, 89);
                    border-color:rgb(74, 82, 89);
                    box-shadow:-1px 0 0 0 rgb(74, 82, 89);
                    color: white;
                }
            }
            .left{
                margin-top: 10px;
                display: flex;
                align-items: center;
                & /deep/ .el-radio-button__inner{
                    background: rgb(74, 82, 89);
                    border-color:rgb(74, 82, 89);
                        box-shadow:-1px 0 0 0 rgb(74, 82, 89);
                    color: white;
                }
                & /deep/ .is-active{
                    .el-radio-button__inner{
                        background: rgb(28, 30, 32);
                        border-color:rgb(28, 30, 32);
                        box-shadow:-1px 0 0 0 rgb(28, 30, 32);
                        
                    }
                }
            
            }
            & /deep/ .el-button--primary{
                background-color: rgb(74, 82, 89);
                border-color: rgb(74, 82, 89);
                margin-left:10px;
            }
            .right{
                .search-box{
                    display: inline-block;
                    margin-left:10px;
                    & /deep/ .el-input__inner{
                        background: rgb(74, 82, 89);
                        color: white;
                    }
                }
                .bg_color{
                    background-color: #4a5259;
                    color: white;
                }
                .picker-box{
                    margin-left:10px;
                    display: inline-block;
                    & /deep/ .el-input__inner{
                        background: rgb(74, 82, 89);
                        color: white;
                    }
                }
            }
        }
    }
    .top-hint-box{
        display: inline-flex;
        align-items: center;
        margin: 10px 0px 0px;
        padding-bottom: 10px;
        width: 100%;
        position: relative;
        &-border{
            border-bottom:1px solid rgba(200,200,200,.4);
        }
        .big-hint-text{
            font-size:18px;
        }
        .hint-text{
            margin-left: 10px;
            color:white;
        }
        .hint-flex{
            display: inline-flex;
            .hint-item{
                margin-left:10px;
                cursor: pointer;
                color: white;
                .fontSize{
                    font-size: 14px;
                }
            }
            .hint-left{
            }
            .active{
            }
        }
    }
    .desktop-box{
        margin-bottom:50px;
        margin-left:20px;
        .box-col{
            vertical-align: top;
            display: inline-block;
            width:200px;
            margin:16px 20px 40px;
            cursor: pointer;
            position: relative;
            &.active{
                & /deep/ .el-card{
                    transform: scale(1.2);
                    box-shadow: 0 2px 12px rgba(0, 0 ,0,.3 );
                }
            }
            & /deep/ .el-card__header{
                padding:10px;
            }
            & /deep/ .el-card{
                border-radius: 8px;
                overflow: hidden;
                box-shadow: none;
                height: 190px;
            }
            .card-item{
                & /deep/ .el-card__body{
                    padding:0px;
                }
            }
            .box-disabled{
                display: none;
                font-size:20px;
                color: white;
                align-items: center;
                justify-content: center;
                position: absolute;
                top:0;
                left:0;
                bottom:0;
                right:0;
                background-color: rgba(0,0,0,.8);
            }
            &.disabled{
                .box-disabled{
                    display: flex;
                    
                }
            }
            &.bg1{
                & /deep/ .el-card{
                    background: rgb(249, 249, 249);
                }
            }
            &.bg2{
                & /deep/ .el-card{
                    background: #87ffa7;
                }
            }
            &.bg3{
                & /deep/ .el-card{
                    background: rgb(255, 239, 193);
                }
            }
            &.bg4{
                & /deep/ .el-card{
                    background: rgb(255, 204, 204);
                }
            }
            &.bg5{
                & /deep/ .el-card{
                    background: rgb(74, 81, 89);
                    color: white;
                }
            }
            .box-content{
                padding:10px;
                line-height: 1.4;
                min-height: 100px;
                font-size:12px;
                .vip-box{
                    margin-bottom: 6px;
                    .vip-label{
                        color: #fff;
                        border-radius: 50px;
                        padding: 2px 8px;
                        background-color: red;
                        line-height: 1;
                        font-size: 10px;
                    }
                }
                .margin-center{
                    text-align: center;
                    h2{
                        border-radius: 4px;
                        margin-top: 15px;
                        background: rgba(0,0,0,.15);
                        color: white;
                        display: inline-block;
                        padding:2px 10px;
                    }

                }
            }
            .desktop-hover-box{
                display: flex;
                align-items: center;
                position: absolute;
                bottom:-36px;
                left:0;
                right:0;
                height: 36px;
                display: none;
                button{
                    flex:1;
                    margin: auto;
                    border-radius: 0;
                }
            }
            &:hover{
                .desktop-hover-box{
                    display: flex;
                }
            }
        }
    }
}
</style>