<template>
    <div>
        <el-dialog
            :title="member_info_config.title"
            v-model="member_info_config.is_show"
            width="800px"
            :show-close="false"
            :close-on-click-modal="false"
            :close-on-press-escape="false">
            <el-form ref="cancelForm" @submit.native.prevent>
                <div class="search">
                    <el-input class="size_input" v-model="search_config.form.member" placeholder="请输入内容" @keyup.enter.native="searchHandle"></el-input>
                    <el-button class="global-ml-10" type="primary" icon="el-icon-search" @click="searchHandle">搜索</el-button>
                </div>
                <el-table
                    :data="member_info_config.table.list"
                    border
                    style="width: 100%"
                    class="member-list-dialog"
                    highlight-current-row
                    @current-change="checkMemberCallback">
                    <el-table-column prop="name" label="姓名" width="180"></el-table-column>
                    <el-table-column prop="phone" label="手机号码" width="180"></el-table-column>
                    <el-table-column prop="grade_name" label="会员等级"></el-table-column>
                </el-table>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button type="primary" @click="agreeHandle">保存</el-button>
                    <el-button @click="cancelHandle">取消</el-button>
                </span>
            </template>

            <div class="pagination-box global-mt-20">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pagination.page"
                    :page-sizes="pagination.page_sizes"
                    :page-size="pagination.page_size"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pagination.total">
                </el-pagination>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {memberApi} from '@/api'
export default {
    props:{
        is_show:[Boolean]
    },
    watch:{
        is_show:{
            handler(newVal){
                this.member_info_config.is_show = newVal
                if(newVal){
                    this.getList()
                }
            },
            immediate:true,
            deep:true
        },
        'member_info_config.is_show':{
            handler(newVal){
                this.$emit('update:is_show',newVal)
            },
            deep:true
        }
    },
    data(){
        return{
            member_info_config:{ //会员信息弹窗
                title:'会员信息',
                is_show:false,
                table:{
                    is_check:true,
                     column_key:[
                        {
                            prop:'name',
                            label:'名字'
                        },
                        {
                            prop:'phone',
                            label:'手机号码'
                        },
                        {
                            prop:'grade_name',
                            label:'会员等级'
                        }
                    ],
                    list:[],
                    table_check:null //预选中的会员信息（还未保存）
                }
            },
            search_config:{
                form:{
                    member:''
                }
            },
            pagination:{
                page:1,
                page_size:10,
                total:0,
                page_sizes:[10,20,30]
            },
        }
    },
    methods:{
        /**
         * 获取会员列表
         */
        getList(){
            memberApi.memberUserList({
                page:this.pagination.page,
                pageSize:this.pagination.page_size,
                member:this.search_config.form.member
            }).then(res=>{
                this.pagination.total = res.data.total
                this.member_info_config.table.list = res.data.list
            })
        },
        /**
         * 选择会员 - 取消
         */
        cancelHandle(){
            this.resetForm()
            this.member_info_config.is_show = false
        },
        /**
         * 选择会员 - 确认
         */
        agreeHandle(){
            if(!this.member_info_config.table.table_check){
                this.$message.warning('请先选择会员')
                return;
            }
            this.$emit('agree',this.member_info_config.table.table_check)
            this.cancelHandle()
        },
        resetForm(){
            this.pagination.page = 1
            this.search_config.form.member = ''
            this.member_info_config.table.table_check = null
        },
        /**
         * 搜索
         */
        searchHandle(){
            this.getList()
        },
        /**
         * 返回选中的会员信息
         */
        checkMemberCallback(val){
            this.member_info_config.table.table_check = val
        },
        handleSizeChange(page_size){
            this.pagination.page_size = page_size
            this.getList()
        },
        handleCurrentChange(page){
            this.pagination.page = page
            this.getList()
        },
    }
}
</script>
<style lang="scss" scoped>
.member-list-dialog{
    & /deep/ tr{
        cursor: pointer;
        &.current-row{
            td{
                background-color: rgb(64, 158, 255);
                color: white;
            }
        }
    }
}
.search{
    display: flex;
    margin-bottom: 10px;
    .size_input{
        width: 300px;
    }
}
</style>