<template>
    <div class="table-desk-dialog-component">
        <el-dialog
            width="800px"
            :title="dialog_config.title"
            v-model="dialog_config.is_show"
            :show-close="false"
            :close-on-click-modal="false"
            :close-on-press-escape="false">
            <div class="search">
                <el-input class="size_input" v-model="search_config.form.ro_room_name" placeholder="请输入台位号" @keyup.enter.native="searchHandle"></el-input>
                <el-checkbox class="global-ml-20" v-model="search_config.form.is_precise" label="精确搜索"></el-checkbox>
                <el-button class="global-ml-10" type="primary" icon="el-icon-search" @click="searchHandle">搜索</el-button>
                <el-button class="global-ml-10" @click="resetHandle">重置</el-button>
            </div>
            <div>
                <table-component class="table-desk-table" :table="dialog_config.table" @handleCurrentChange="checkItem">
                    <template #default="data">
                        <div>{{data.scope.row[data.item.prop]}}</div>
                    </template>
                </table-component>
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="cancelFun">取消</el-button>
                    <el-button type="primary" @click="agreeFun">确认</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>
<script>
import tableComponent from '@/components/table'
import {pubDeskApi} from '@/api'
export default {
    name:'table-desk-dialog-component',
    components:{
        tableComponent
    },
    props:{
        is_show:[Boolean],
        // 台位列表
        list:Array,
        // 当前点击的台位
        current_desk_info:Object,
        // 1转台 2换台 3并台
        type:[String,Number],
    },
    watch:{
        is_show:{
            handler(newVal){
                this.dialog_config.is_show = newVal
                if(newVal){
                    this.getList()
                }
            },
            immediate:true,
            deep:true
        },
        'dialog_config.is_show':{
            handler(newVal){
                this.$emit('update:is_show',newVal)
            },
            deep:true
        }
    },
    data(){
        return{
            dialog_config:{
                title:'选择台位',
                is_show:false,
                form:{},
                table:{
                    height:400,
                    highlight_current_row:true,
                    column_key:[
                        {
                            prop:'ro_room_name',
                            label:'台位号'
                        },
                        {
                            prop:'ro_region_name',
                            label:'台位区域'
                        },
                        {
                            prop:'staff_name',
                            label:'订台人'
                        },
                        
                    ],
                    list:[]
                }
            },
            check_item:null,
            search_config:{
                form:{
                    ro_room_name:'',
                    is_precise:false
                }
            }
        }
    },
    methods:{
        /**
         * 搜索
         */
        searchHandle(){
            if(!this.search_config.form.ro_room_name.trim()){
                this.getList()
                return;
            }
            let new_list = []
            let list = this.list
            for(let i in list){
                if(this.search_config.form.is_precise){ //精确搜索
                    if(list[i].ro_room_name.toUpperCase() == this.search_config.form.ro_room_name.toUpperCase()){
                        list[i]['disabled'] = this.desktopCheckDisabledFun(list[i])
                        new_list.push(this.list[i])
                    }
                }else{ //模糊搜索
                    if(list[i].ro_room_name.toUpperCase().indexOf(this.search_config.form.ro_room_name.toUpperCase()) != -1){
                        list[i]['disabled'] = this.desktopCheckDisabledFun(list[i])
                        new_list.push(this.list[i])
                    }
                }
            }
            this.dialog_config.table.list = new_list
        },
        resetHandle(){
            this.getList()
        },
        resetSearchConfigParams(){
            this.search_config.form.ro_room_name = ''
        },
        /**
         * 渲染台位列表
         */
        getList(){
            let list = JSON.parse(JSON.stringify(this.list))
            for(let i in list){
                list[i]['disabled'] = this.desktopCheckDisabledFun(list[i])
            }
            this.dialog_config.table.list = list
        },
        /**
         * 禁用 type：0转台 1换台 2并台
         */
        desktopCheckDisabledFun(select_item){
            if(this.type == 0){
                // 同一个台位 || 开台状态 || 预定状态  返回true则禁用
                if(this.current_desk_info.id == select_item.id || select_item.open_room_id || (select_item.reserve &&select_item.reserve.station_reserve_id) || select_item.ro_is_lock == 2){
                    return true
                }
            }else if(this.type == 1){
                if(this.current_desk_info.reserve && this.current_desk_info.reserve.station_reserve_id){
                    // 预定换台
                    if(select_item.reserve && this.current_desk_info.id != select_item.id && select_item.ro_is_lock != 2){
                        return false
                    }else{
                        return true
                    }
                }else{
                    // 开台换台
                    if(select_item.open_room_id && this.current_desk_info.id != select_item.id && select_item.ro_is_lock != 2){
                        return false
                    }else{
                        return true
                    }
                }
            }else if(this.type == 2){
                if(select_item.open_room_id && this.current_desk_info.id != select_item.id && select_item.ro_is_lock != 2){
                    return false
                }else{
                    return true
                }
            }
            return false
        },
        checkItem(item,preItem){
            if(this.desktopCheckDisabledFun(item)){ // 禁用的
                this.check_item = null
                return;
            }
            this.check_item = item
        },
        cancelFun(){
            this.dialog_config.table.list = []
            this.check_item = null
            this.dialog_config.is_show = false
            this.resetSearchConfigParams()
        },
        agreeFun(){
            if(!this.check_item){
                this.$message.warning('请先选择台位')
                return;
            }
            this.$emit('agree',this.check_item)
            this.cancelFun()
        },
        // handleSizeChange(page_size){
        //     this.pagination.page_size = page_size
        //     this.getList()
        // },
        // handleCurrentChange(page){
        //     this.pagination.page = page
        //     this.getList()
        // }
    }
}
</script>
<style lang="scss" scoped>
.table-desk-dialog-component{
    .table-desk-table{
        & /deep/ tr{
            cursor: pointer;
            &.current-row{
                td{
                    background-color: rgb(64, 158, 255);
                    color: white;
                }
            }
        }
    }
}
.search{
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    .size_input{
        width: 300px;
    }
}
</style>