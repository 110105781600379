<template>
    <div class="staff-dialog-component">
        <el-dialog
            width="800px"
            :title="dialog_config.title"
            v-model="dialog_config.is_show"
            :show-close="false"
            :close-on-click-modal="false"
            :close-on-press-escape="false">
            <el-form ref="cancelForm" @submit.native.prevent>
                <div class="search">
                    <el-input class="size_input" v-model="search_config.form.realname" placeholder="请输入内容" @keyup.enter.native="searchHandle"></el-input>
                    <el-button class="global-ml-10" type="primary" icon="el-icon-search" @click="searchHandle">搜索</el-button>
                </div>
                <table-component class="staff-table" :table="dialog_config.table" @handleCurrentChange="checkItem">
                    <template #default="data">
                        <div>{{data.scope.row[data.item.prop]}}</div>
                    </template>
                </table-component>
                <div class="pagination-box">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="pagination.page"
                        :page-sizes="pagination.page_sizes"
                        :page-size="pagination.page_size"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="pagination.total">
                    </el-pagination>
                </div>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="cancelFun">取消</el-button>
                    <el-button type="primary" @click="agreeFun">确认</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>
<script>
import tableComponent from '@/components/table'
import {cashierApi} from '@/api'
export default {
    name:'staff-dialog-component',
    components:{
        tableComponent
    },
    props:{
        is_show:[Boolean]
    },
    watch:{
        is_show:{
            handler(newVal){
                console.log('newVal',newVal)
                this.dialog_config.is_show = newVal
                if(newVal){
                    this.getList()
                }
            },
            immediate:true,
            deep:true
        },
        'dialog_config.is_show':{
            handler(newVal){
                this.$emit('update:is_show',newVal)
            },
            deep:true
        }
    },
    data(){
        return{
            dialog_config:{
                title:'选择订台人',
                is_show:false,
                form:{},
                table:{
                    highlight_current_row:true,
                    column_key:[
                        {
                            prop:'realname',
                            label:'员工姓名'
                        },
                        {
                            prop:'user_department',
                            label:'部门信息'
                        },
                        {
                            prop:'phone',
                            label:'手机号'
                        },
                    ],
                    list:[]
                }
            },
            search_config:{
                form:{
                    realname:'',
                }
            },
            pagination:{
                page:1,
                page_size:10,
                total:0,
                page_sizes:[10,20,30]
            },
            check_item:null
        }
    },
    methods:{
        /**
         * 获取员工列表
         */
        getList(){
            cashierApi.staffEmployeeList({
                page:this.pagination.page,
                pageSize:this.pagination.page_size,
                realname:this.search_config.form.realname
            }).then(res=>{
                this.pagination.total = res.data.total
                this.dialog_config.table.list = res.data.list
            })
        },
        resetForm(){
            this.pagination.page = 1
            this.search_config.form.realname = ''
        },
        /**
         * 搜索
         */
        searchHandle(){
            this.getList()
        },
        checkItem(item,preItem){
            this.check_item = item
        },
        cancelFun(){
            this.resetForm()
            this.dialog_config.table.list = []
            this.check_item = null
            this.dialog_config.is_show = false
        },
        agreeFun(){
            if(!this.check_item){
                this.$message.warning('请先选择订台人')
                return;
            }
            this.$emit('agree',this.check_item)
            this.cancelFun()
        },
        handleSizeChange(page_size){
            this.pagination.page_size = page_size
            this.getList()
        },
        handleCurrentChange(page){
            this.pagination.page = page
            this.getList()
        }
    }
}
</script>
<style lang="scss" scoped>
.staff-dialog-component{
    .staff-table{
        & /deep/ tr{
            cursor: pointer;
            &.current-row{
                td{
                    background-color: rgb(64, 158, 255);
                    color: white;
                }
            }
        }
    }
}
.search{
    display: flex;
    margin-bottom: 10px;
    .size_input{
        width: 300px;
    }
}
</style>